import React, { Component } from 'react';
import { Grid, Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Hobbies.css';

class Hobbies extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <Grid>
                    <h2>A place to share my hobbies! Here you can find snippets from my escapades in leathercraft and more.</h2>
                    <br></br>
                    <br></br>
                    <h3>The Beginning</h3>
                    <Row>
                        <Col xs={6} sm={4}>
                            <Image src="./assets/Hobbies/TheBeginning/TheHide.png" className="screenshot" />
                        </Col>
                        <Col xs={6} sm={4}>
                            <Image src="./assets/Hobbies/TheBeginning/TheSketch.png" className="screenshot" />
                        </Col>
                        <Col xs={6} sm={4}>
                            <Image src="./assets/Hobbies/TheBeginning/TheTools.png" className="screenshot" />
                        </Col>
                        <Col xs={6} sm={4}>
                            <Image src="./assets/Hobbies/TheBeginning/Pieces.png" className="screenshot" />
                        </Col>
                        <Col xs={6} sm={4}>
                            <Image src="./assets/Hobbies/TheBeginning/Finished.png" className="screenshot" />
                        </Col>
                    </Row>
                    
                    <h3>Bags of All Kinds</h3>
                    <Row>
                        <Col xs={6} sm={4}>
                            <Image src="./assets/Hobbies/BagsofAllKinds/BackpackDavid.jpg" className="screenshot" />
                        </Col>
                        <Col xs={6} sm={4}>
                            <Image src="./assets/Hobbies/BagsofAllKinds/Briefcase.jpg" className="screenshot" />
                        </Col>
                        <Col xs={6} sm={4}>
                            <Image src="./assets/Hobbies/BagsofAllKinds/PurseAlexHanging.jpg" className="screenshot" />
                        </Col>
                        <Col xs={6} sm={4}>
                            <Image src="./assets/Hobbies/BagsofAllKinds/PurseAlexOpen.jpg" className="screenshot" />
                        </Col>
                        <Col xs={6} sm={4}>
                            <Image src="./assets/Hobbies/BagsofAllKinds/PurseMorgan.jpg" className="screenshot" />
                        </Col>
                        <Col xs={6} sm={4}>
                            <Image src="./assets/Hobbies/BagsofAllKinds/PurseMother.jpg" className="screenshot" />
                        </Col>
                    </Row>

                    <h3>Misc Projects</h3>
                    <Row>
                        <Col xs={6} sm={3}>
                            <Image src="./assets/Hobbies/Misc/GuitarStrap.jpg" className="screenshot" />
                        </Col>
                        <Col xs={6} sm={3}>
                            <Image src="./assets/Hobbies/Misc/Tooling.jpg" className="screenshot" />
                        </Col>
                        <Col xs={6} sm={3}>
                            <Image src="./assets/Hobbies/Misc/WalletExterior.jpg" className="screenshot" />
                        </Col>
                        <Col xs={6} sm={3}>
                            <Image src="./assets/Hobbies/Misc/WalletInterior.jpg" className="screenshot" />
                        </Col>
                        <Col xs={6} sm={3}>
                            <Image src="./assets/Hobbies/Misc/LaptopCover.jpg" className="screenshot" />
                        </Col>
                    </Row>


                </Grid>
                <Grid>
                    <h2>Professional Projects</h2>
                    <Row>
                        <Col xs={12} sm={4}>
                            <Link to={'/SimpleTransitions'} id="link">
                                <Image src="assets/SimpleTransitionsCover.png" className="next-project-hub" alt="..." />
                            </Link>
                        </Col>
                        <Col xs={12} sm={4}>
                            <Link to={'/ServiceHub'} id="link">
                                <Image src="assets/ServiceHubCover.png" className="next-project-hub" alt="..." />
                            </Link>
                        </Col>
                        <Col xs={12} sm={4}>
                            <Link to={'/Spotify'} id="link">
                                <Image src="assets/SpotifyCover.png" className="next-project-hub" alt="..." />
                            </Link>
                        </Col>
                    </Row>
                </Grid>
            </div>
            
        );
    }
}

export default Hobbies;